import bookLogService from "@/services/bookLog.service";

const state = {
  bookLog: [],
  imageResponse: [],
  make: [],
  model: [],
  year: [],
  trim: [],
  bookLogDetail: {},
  vinSearch: {},
};

const actions = {
  getBookLog({ commit }, query) {
    return bookLogService.bookLog(commit, query);
  },

  uploadImage({ commit }, query) {
    return bookLogService.uploadImage(commit, query);
  },

  deleteImage({ commit }, query) {
    return bookLogService.deleteImage(commit, query);
  },

  getMake({ commit }, query) {
    return bookLogService.getMake(commit, query);
  },

  getModel({ commit }, query) {
    return bookLogService.getModel(commit, query);
  },

  getYear({ commit }, query) {
    return bookLogService.getYear(commit, query);
  },

  getTrim({ commit }, query) {
    return bookLogService.getTrim(commit, query);
  },

  saveBookLog({ commit }, query) {
    return bookLogService.saveBookLog(commit, query);
  },

  getBookLogById({ commit }, query) {
    return bookLogService.getBookLogById(commit, query);
  },

  saveBookLogById({ commit }, query) {
    return bookLogService.saveBookLogById(commit, query);
  },

  sendPdf({ commit }, query) {
    return bookLogService.sendPdf(commit, query);
  },

  vinSearch({ commit }, data) {
    return bookLogService.vinSearch(commit, data);
  },
};

const mutations = {
  setBookLog(state, response) {
    state.bookLog = response.data;
  },

  setImageResponse(state, response) {
    state.imageResponse = response.data;
  },

  setMake(state, response) {
    state.make = response.data;
  },

  setModel(state, response) {
    state.model = response.data;
  },

  setYear(state, response) {
    state.year = response.data;
  },

  setTrim(state, response) {
    state.trim = response.data;
  },

  setBookLogById(state, response) {
    state.bookLogDetail = response.data;
  },

  setVinSearchData(state, response) {
    state.vinSearch = response.data;
  },
};

const getters = {
  getBookLog(state) {
    return state.bookLog;
  },

  getImageResponse(state) {
    return state.imageResponse;
  },

  getMake(state) {
    return state.make;
  },

  getModel(state) {
    return state.model;
  },

  getYear(state) {
    return state.year;
  },

  getTrim(state) {
    return state.trim;
  },

  getBookLogById(state) {
    return state.bookLogDetail;
  },

  getVinSearchData(state) {
    return state.vinSearch;
  },
};

export const bookLog = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
