import Vue from "vue";
import Vuex from "vuex";

import { user } from "./modules/user";
import { posts } from "./modules/post";
import { vinSearchReport } from "./modules/vinSearch";
import { marketingCampaign } from "./modules/marketingCampaign";
import { messages } from "./modules/message";
import { postThread } from "./modules/postThread";
import { members } from "./modules/member";
import { leads } from "./modules/lead";
import { carHistory } from "./modules/carHistory";
import { carHistoryReport } from "./modules/carHistoryReport";
import { payment } from "./modules/payment";
import { interchange } from "./modules/interchange";
import { bookLog } from "./modules/bookLog";
import { searchInventory } from "./modules/searchInventory";
import { dmv } from "./modules/dmvProcessing";
import { news } from "./modules/news";
import { invoice } from "./modules/invoice";
import { hollander } from "./modules/hollander";
import { vehicleHistory } from "./modules/vehicleHistory";
import { coreBuying } from "./modules/coreBuying";
import { countryDetails } from "./modules/countryDetails";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    message: "",
  },
  getters: {
    getLoaderStatus(state) {
      return state.loading;
    },
    getLoaderMessage(state) {
      return state.message;
    },
  },
  mutations: {
    setLoader(state, status) {
      state.loading = status;
    },
    setLoaderMessage(state, message) {
      state.message = message;
    },
  },
  actions: {},
  modules: {
    user,
    posts,
    vinSearchReport,
    marketingCampaign,
    messages,
    postThread,
    members,
    leads,
    carHistory,
    carHistoryReport,
    payment,
    interchange,
    bookLog,
    searchInventory,
    dmv,
    news,
    invoice,
    hollander,
    vehicleHistory,
    coreBuying,
    countryDetails
  },
});
