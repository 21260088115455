import coreBuyingService from "@/services/coreBuying.service";

const state = {
  coreBuying: [],
  coreBuyingSearch: [],
  coreBuyingUsers: [],
  coreBuyingParts: [],
};

const actions = {
  getCoreBuying({ commit }, payload) {
    return coreBuyingService.getCoreBuying(commit, payload);
  },
  saveCoreBuying({ commit }, payload) {
    return coreBuyingService.saveCoreBuying(commit, payload);
  },
  updateCoreBuying({ commit }, query) {
    return coreBuyingService.updateCoreBuying(commit, query);
  },
  deleteCoreBuying({ commit }, query) {
    return coreBuyingService.deleteCoreBuying(commit, query);
  },
  getCoreBuyingUsers({ commit }, query) {
    return coreBuyingService.getCoreBuyingUsers(commit, query);
  },
  getCoreBuyingSearch({ commit }, query) {
    return coreBuyingService.getCoreBuyingSearch(commit, query);
  },
  getCoreBuyingParts({ commit }) {
    return coreBuyingService.getCoreBuyingParts(commit);
  },
};

const mutations = {
  setCoreBuying(state, response) {
    state.coreBuying = response.data;
  },
  setCoreBuyingSearch(state, response) {
    state.coreBuyingSearch = response.data;
  },
  setCoreBuyingUsers(state, response) {
    state.coreBuyingUsers = response.data;
  },
  setCoreBuyingParts(state, response) {
    state.coreBuyingParts = response.data;
  }
};

const getters = {
  getCoreBuying(state) {
    return state.coreBuying;
  },
  getCoreBuyingSearch(state) {
    return state.coreBuyingSearch;
  },
  getCoreBuyingUsers(state) {
    return state.coreBuyingUsers;
  },
  getCoreBuyingParts(state) {
    return state.coreBuyingParts;
  },
};

export const coreBuying = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
