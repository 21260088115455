import carHistoryService from "@/services/carHistoryReport.service";

const state = {
  carHistoryReport: [],
};

const actions = {
  sendHistoryReportMail({ commit }, payload) {
    return carHistoryService.sendCarHistoryReport(commit, payload);
  },
};

const mutations = {
  setCarHistoryReport(state, response) {
    state.carHistoryReport = response.data;
  },
};

const getters = {
  getCarHistoryReport(state) {
    return state.carHistoryReport;
  },
};

export const carHistoryReport = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
