import http from "@/services/http.service";
import { NEWS } from "@/constants/apiUrls";

const getNewsList = async (commit, payload) => {
  const response = await http.get(NEWS, {
    params: payload,
  });
  commit("setNewsList", response.data);
};

const newsTotalUnread = async (commit) => {
  const response = await http.post(NEWS + "/total-unread");
  commit("setNewsTotalUnread", response.data.count);
};

const markAsRead = async (commit, payload) => {
  await http.post(NEWS + "/mark-as-read", {id: payload.id});
};

export default {
  markAsRead,
  newsTotalUnread,
  getNewsList,
};
