import hollanderService from "@/services/hollander.service";

const state = {
  years: [],
  makeList: [],
  modelList: [],
};

const actions = {
  getYears({ commit }, payload) {
    return hollanderService.getYears(commit, payload);
  },

  getMake({ commit }, payload) {
    return hollanderService.getMake(commit, payload);
  },

  getModel({ commit }, payload) {
    return hollanderService.getModel(commit, payload);
  },
};

const mutations = {
  setYears(state, response) {
    state.years = response.data;
  },

  setMake(state, response) {
    state.makeList = response.data;
  },

  setModel(state, response) {
    state.modelList = response.data;
  },
};

const getters = {
  getYears(state) {
    return state.years;
  },

  getMake(state) {
    return state.makeList;
  },

  getModel(state) {
    return state.modelList;
  },
};

export const hollander = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
