import newsService from "@/services/news.service";

const state = {
  newsList: [],
  totalUnread: 0
};

const actions = {
  getNewsList({ commit }, payload) {
    return newsService.getNewsList(commit, payload);
  },

  newsTotalUnread({ commit }) {
    return newsService.newsTotalUnread(commit);
  },

  markAsRead({ commit }, payload) {
    return newsService.markAsRead(commit, payload);
  },
};

const mutations = {
  setNewsList(state, response) {
    state.newsList = response;
  },

  setNewsTotalUnread(state, response) {
    state.totalUnread = response;
  },
};

const getters = {
  getNewsList(state) {
    return state.newsList;
  },

  getNewsTotalUnread(state) {
    return state.totalUnread;
  },
};

export const news = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
