import http from "@/services/http.service";
import { VIN_HTML_REPORT, PREMIUM_VIN_DECODER_REPORT } from "@/constants/apiUrls";

const getVinHtmlReport = async (commit, payload) => {
  const response = await http.post(VIN_HTML_REPORT, payload);
  commit("setVinHtmlReport", response.data);
};

const getPremiumVinDecoderReport = async (commit, payload) => {
  const response = await http.post(PREMIUM_VIN_DECODER_REPORT, payload);
  commit("setVinHtmlReport", response.data);
};

export default {
  getVinHtmlReport,
  getPremiumVinDecoderReport,
};
