import vinService from "@/services/vinSearch.service";

const state = {
  vinSearchReport: [],
  vin: null,
  pdf_path: null,
  pdf_url: null,
  previousVinSearchReport: [],
};

const actions = {
  getVinSearchReport({ commit }, payload) {
    return vinService.vinSearchReport(commit, payload);
  },

  getPreviousVinSearchReport({ commit }, payload) {
    return vinService.getPreviousVinSearchReport(commit, payload);
  },

  sendPdfToMailAction({ commit }, payload) {
    return vinService.sendPdfToMail(commit, payload);
  },
};

const mutations = {
  setVinSearchReport(state, response) {
    state.vinSearchReport = response.data;
  },

  setVin(state, vin) {
    state.vin = vin;
  },

  setPdfPath(state, path) {
    state.pdf_path = path;
  },

  setPdfUrl(state, url) {
    state.pdf_url = url;
  },

  setPreviousVinSearchReport(state, response) {
    state.previousVinSearchReport = response.data;
  },
};

const getters = {
  getVinSearchReport(state) {
    return state.vinSearchReport;
  },
  getVin(state) {
    return state.vin;
  },

  getPdfPath(state) {
    return state.pdf_path;
  },

  getPdfUrl(state) {
    return state.pdf_url;
  },

  getPreviousVinSearchReport(state) {
    return state.previousVinSearchReport;
  },
};

export const vinSearchReport = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
