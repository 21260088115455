import http from "@/services/http.service";
import {
  CORE_BUYING,
  CORE_BUYING_USERS,
  CORE_BUYING_SEARCH,
  CORE_BUYING_PARTS
} from "../constants/apiUrls";
import { ITEMS_PER_PAGE } from "@/constants/common";

const saveCoreBuying = async (commit, data) => {
  await http.post(CORE_BUYING, data);
  await getCoreBuying(commit, {
    search: null,
    items_per_page: ITEMS_PER_PAGE,
    page: 1,
  });
};

const updateCoreBuying = async (commit, data) => {
  await http.put(
    CORE_BUYING+`/${data.id}`,
    data
  );
  await getCoreBuying(commit, {
    search: null,
    items_per_page: ITEMS_PER_PAGE,
    page: 1,
  });
};

const getCoreBuying = async (commit, payload) => {
  const response = await http.get(CORE_BUYING, { params: payload });
  commit("setCoreBuying", response);
};

const deleteCoreBuying = async (commit, data) => {
  await http.delete(CORE_BUYING+`/${data.id}`);
  await getCoreBuying(commit, {
    search: null,
    items_per_page: ITEMS_PER_PAGE,
    page: 1,
  });
};

const getCoreBuyingUsers = async (commit, payload) => {
  const response = await http.get(CORE_BUYING_USERS, { params: payload });
  commit("setCoreBuyingUsers", response);
};

const getCoreBuyingSearch = async (commit, payload) => {
  const response = await http.get(CORE_BUYING_SEARCH, { params: payload });
  commit("setCoreBuyingSearch", response);
};

const getCoreBuyingParts = async (commit) => {
  const response = await http.get(CORE_BUYING_PARTS);
  commit("setCoreBuyingParts", response.data);
};

export default {
  getCoreBuying,
  saveCoreBuying,
  updateCoreBuying,
  deleteCoreBuying,
  getCoreBuyingUsers,
  getCoreBuyingSearch,
  getCoreBuyingParts
};
