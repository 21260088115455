import http from "@/services/http.service";
import {
  BOOKLOG,
  IMAGE,
  MAKEOPTION,
  MODELOPTION,
  YEAROPTION,
  TRIMOPTION,
  VIN_SEARCH,
} from "@/constants/apiUrls";
import numberUtility from "@/utils/number.utility";
import tokenService from "./token.service";
import axios from "axios";
import { SEND_PDF_TO_MAIL } from "../constants/apiUrls";

const bookLog = async (commit, query) => {
  const response = await http.get(BOOKLOG, { params: query });
  commit("setBookLog", response);
};

const uploadImage = async (commit, formData) => {
  const response = await axios.post(
    process.env.VUE_APP_API_HOST + IMAGE,
    formData,
    {
      headers: {
        Authorization: `Bearer ${tokenService.getAccessToken()}`,
      },
    }
  );
  commit("setImageResponse", response);
};

const deleteImage = async (commit, query) => {
  await http.delete(`${IMAGE}/${query}`);
};

const getMake = async (commit) => {
  const response = await http.get(MAKEOPTION);
  commit("setMake", response.data);
};

const getModel = async (commit, query) => {
  const response = await http.get(MODELOPTION, { params: query });
  commit("setModel", response.data);
};

const getYear = async (commit, query) => {
  const response = await http.get(YEAROPTION, { params: query });
  commit("setYear", response.data);
};

const getTrim = async (commit, query) => {
  const response = await http.get(TRIMOPTION, { params: query });
  commit("setTrim", response.data);
};

const vinSearch = async (commit, data) => {
  const response = await http.post(VIN_SEARCH, data);
  commit("setVinSearchData", response.data);
};

const saveBookLog = async (commit, data) => {
  data.purchase_cost = data.purchasecost.toString().replace(/[^0-9/.]/g, "");
  data.source_tel_no = numberUtility.toSimplePhone(data.source_tel_no);
  await http.post(BOOKLOG, data);
};

const getBookLogById = async (commit, query) => {
  const response = await http.get(BOOKLOG + "/" + query);
  response.data.data.purchasecost = response.data.data.purchase_cost;
  response.data.data.source_tel_no = numberUtility.toUsPhone(
    response.data.data.source_tel_no
  );
  commit("setBookLogById", response.data);
};

const saveBookLogById = async (commit, data) => {
  data.purchase_cost = data.purchasecost.toString().replace(/[^0-9/.]/g, "");
  data.source_tel_no = numberUtility.toSimplePhone(data.source_tel_no);
  await http.put(BOOKLOG + "/" + data.id, data);
};

const sendPdf = async (commit, data) => {
  await http.post(BOOKLOG + SEND_PDF_TO_MAIL.replace("api", "/" + data.id), {
    email: data.email,
  });
};

export default {
  bookLog,
  uploadImage,
  deleteImage,
  getMake,
  getModel,
  getYear,
  getTrim,
  saveBookLog,
  getBookLogById,
  saveBookLogById,
  sendPdf,
  vinSearch,
};
