import vehicleHistoryService from "@/services/vehicleHistory.service";

const state = {
  vinHtmlReport: [],
};

const actions = {
  getVinHtmlReport({ commit }, payload) {
    return vehicleHistoryService.getVinHtmlReport(commit, payload);
  },
  getPremiumVinDecoderReport({ commit }, payload) {
    return vehicleHistoryService.getPremiumVinDecoderReport(commit, payload);
  },
};

const mutations = {
  setVinHtmlReport(state, response) {
    state.vinHtmlReport = response;
  },
};

const getters = {
  getVinHtmlReportInfo(state) {
    return state.vinHtmlReport;
  },
};

export const vehicleHistory = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
