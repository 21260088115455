import carHistoryService from "@/services/carHistory.service";

const state = {
  carHistoryReport: [],
  previousCarHistoryReport: [],
};

const actions = {
  getCarHistoryReport({ commit }, payload) {
    return carHistoryService.getCarHistoryReport(commit, payload);
  },
  getPreviousCarHistoryReport({ commit }, payload) {
    return carHistoryService.getPreviousCarHistoryReport(commit, payload);
  },
};

const mutations = {
  setCarHistoryReport(state, response) {
    state.carHistoryReport = response.data;
  },

  setPreviousCarHistoryReport(state, response) {
    state.previousCarHistoryReport = response.data;
  },
};

const getters = {
  getCarHistoryReport(state) {
    return state.carHistoryReport;
  },
  getPreviousCarHistoryReport(state) {
    return state.previousCarHistoryReport;
  },
};

export const carHistory = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
