import http from "@/services/http.service";
import {
  PART_NAME,
  INVENTORIES,
  SHARE_INVENTORY,
  PART_CODE_NAME,
  OLD_INVENTORY_MODEL,
  OLD_INVENTORY_LIST,
  OLD_INVENTORY_YEAR,
  INVENTORY_TRIM_OPTION,
  SHARE_OLD_INVENTORY,
} from "@/constants/apiUrls";

const getPartNames = async (commit, payload) => {
  const response = await http.get(PART_NAME, { params: payload });
  commit("setPartNames", response.data);
};

const getInventories = async (commit, payload) => {
  const response = await http.post(INVENTORIES, { ...payload });
  commit("setInventories", response.data);
};

const getTrim = async (commit, payload) => {
  const response = await http.post(INVENTORY_TRIM_OPTION, { ...payload });
  commit("setTrim", response.data);
};

const sendPdf = async (commit, data) => {
  await http.post(SHARE_INVENTORY, {
    ...data,
  });
};

const shareOldCarProfileInfoViaMail = async (commit, data) => {
  await http.post(SHARE_OLD_INVENTORY, {
    ...data,
  });
};

const getPartCodeName = async (commit) => {
  const response = await http.get(PART_CODE_NAME);
  response.data.data.forEach((val) => {
    val.label = val.name + " ( " + val.code + " )";
  });
  commit("setPartCodeName", response.data);
};

const getOldInventoryModel = async (commit, payload) => {
  const response = await http.get(OLD_INVENTORY_MODEL, {
    params: payload,
  });
  commit("setOldInventoryModel", response.data);
};

const getOldInventoryYear = async (commit, payload) => {
  const response = await http.get(OLD_INVENTORY_YEAR, {
    params: payload,
  });
  commit("setOldInventoryYear", response.data);
};

const getOldInventoryList = async (commit, payload) => {
  const response = await http.get(OLD_INVENTORY_LIST, {
    params: payload,
  });
  commit("setOldInventoryList", response.data);
};

export default {
  getPartNames,
  getInventories,
  sendPdf,
  shareOldCarProfileInfoViaMail,
  getPartCodeName,
  getOldInventoryYear,
  getOldInventoryModel,
  getOldInventoryList,
  getTrim,
};
