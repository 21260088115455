import countryDetailsService from "@/services/countryDetails.service";
const state = {
    isDarkMode: false
}

const actions = {
  getCountryDetails({ commit }) {
    return countryDetailsService.getCountryDetails(commit);
  },
};

const mutations = {
    setIsDarkMode(state, response) {
      state.isDarkMode = response.data;
    },
  };
  
  const getters = {
    getIsDarkMode(state) {
      return state.isDarkMode;
    },
  };

export const countryDetails = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };