import marketingCampaignService from "@/services/marketingCampaign.service";

const state = {
  marketingContactsSearch: [],
  marketingCampaignSearch: [],
  tagsSearch: [],
  id: null,
  name: null,
  number: null,
  tags: null,
  type: null,
  created_at: null,
};

const actions = {
  getMarketingContacts({ commit }, payload) {
    return marketingCampaignService.getMarketingContacts(commit, payload);
  },
  getTags({ commit }, payload) {
    return marketingCampaignService.getTags(commit, payload);
  },
  saveMarketingContacts({ commit }, payload) {
    return marketingCampaignService.saveMarketingContacts(commit, payload);
  },
  updateMarketingContacts({ commit }, query) {
    return marketingCampaignService.updateMarketingContacts(commit, query);
  },
  deleteMarketingContacts({ commit }, query) {
    return marketingCampaignService.deleteMarketingContacts(commit, query);
  },
  saveMarketingCompaign({ commit }, query) {
    return marketingCampaignService.saveMarketingCompaign(commit, query);
  },
  getMarketingCampaign({ commit }, query) {
    return marketingCampaignService.getMarketingCampaign(commit, query);
  },
};

const mutations = {
  setMarketingContactsSearch(state, response) {
    state.marketingContactsSearch = response.data;
  },
  setTagsSearch(state, response) {
    state.tagsSearch = response.data;
  },
  setMarketingCampaignSearch(state, response) {
    state.marketingCampaignSearch = response.data;
  },
  setId(state, id) {
    return (state.id = id);
  },
  setName(state, name) {
    return (state.name = name);
  },
  setNumber(state, number) {
    return (state.number = number);
  },
  setTags(state, tags) {
    return (state.tags = tags);
  },
  setType(state, type) {
    return (state.type = type);
  },
  setCreated_at(state, created_at) {
    return (state.created_at = created_at);
  },
};

const getters = {
  getMarketingContactsSearch(state) {
    return state.marketingContactsSearch;
  },
  getTagsSearch(state) {
    return state.tagsSearch;
  },
  getMarketingCampaignSearch(state) {
    return state.marketingCampaignSearch;
  },
  getId(state) {
    return state.id;
  },
  getName(state) {
    return state.name;
  },
  getNumber(state) {
    return state.number;
  },
  getTags(state) {
    return state.tags;
  },
  getType(state) {
    return state.type;
  },
  getCreated_at(state) {
    return state.created_at;
  },
};

export const marketingCampaign = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
