import http from "@/services/http.service";
import { CAR_HISTORY_REPORT } from "@/constants/apiUrls";

const sendCarHistoryReport = async (commit, payload) => {
  const response = await http.post(CAR_HISTORY_REPORT, payload);
  commit("setCarHistoryReport", response.data);
};

export default {
  sendCarHistoryReport,
};
