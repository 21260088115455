import http from "@/services/http.service";
import {
  GET_CAR_PROFILES,
  SAVE_DMV_PROCESSING,
  UPDATE_DMV_PROCESSING,
  GET_DMV_PROCESSING,
} from "@/constants/apiUrls";

const saveDmv = async (commit, data) => {
  await http.post(SAVE_DMV_PROCESSING, data);
  await getCarProfiles(commit);
  await getDmvProcessing(commit);
};

const updateDmv = async (commit, data) => {
  await http.put(
    UPDATE_DMV_PROCESSING.replace("{dmv_processing}", data.id),
    data
  );
  await getCarProfiles(commit);
  await getDmvProcessing(commit);
};

const getCarProfiles = async (commit, payload) => {
  const response = await http.get(GET_CAR_PROFILES, { params: payload });
  commit("setCarProfiles", response.data);
};

const getDmvProcessing = async (commit, payload) => {
  const response = await http.get(GET_DMV_PROCESSING, { params: payload });
  commit("setDmvProcessing", response.data);
};

export default {
  saveDmv,
  updateDmv,
  getCarProfiles,
  getDmvProcessing,
};
