import searchInventoryService from "@/services/searchInventory.service";

const state = {
  partNamesData: [],
  inventoryList: [],
  partCodeList: [],
  oldInventoryModelList: [],
  oldInventoryYearList: [],
  oldInventoryList: [],
  trim: [],
  inventoryMeta: {},
};

const actions = {
  getPartNames({ commit }, payload) {
    return searchInventoryService.getPartNames(commit, payload);
  },
  getInventories({ commit }, payload) {
    return searchInventoryService.getInventories(commit, payload);
  },
  sendPdf({ commit }, query) {
    return searchInventoryService.sendPdf(commit, query);
  },
  shareOldCarProfileInfoViaMail({ commit }, query) {
    return searchInventoryService.shareOldCarProfileInfoViaMail(commit, query);
  },
  getPartCodeName({ commit }, payload) {
    return searchInventoryService.getPartCodeName(commit, payload);
  },
  getOldInventoryModel({ commit }, payload) {
    return searchInventoryService.getOldInventoryModel(commit, payload);
  },
  getOldInventoryYear({ commit }, payload) {
    return searchInventoryService.getOldInventoryYear(commit, payload);
  },
  getOldInventoryList({ commit }, payload) {
    return searchInventoryService.getOldInventoryList(commit, payload);
  },
  getTrim({ commit }, query) {
    return searchInventoryService.getTrim(commit, query);
  },
};

const mutations = {
  setPartNames(state, response) {
    state.partNamesData = response.data;
  },
  setInventories(state, response) {
    state.inventoryMeta = response.meta;
    state.inventoryList = response.data;
  },
  setPartCodeName(state, response) {
    state.partCodeList = response.data;
  },
  setOldInventoryYear(state, response) {
    state.oldInventoryYearList = response.data;
  },
  setOldInventoryModel(state, response) {
    state.oldInventoryModelList = response.data;
  },
  setOldInventoryList(state, response) {
    state.inventoryMeta = response.meta;
    state.oldInventoryList = response.data;
  },
  setTrim(state, response) {
    state.trim = response.data;
  },
};

const getters = {
  getPartNames(state) {
    return state.partNamesData;
  },
  getInventories(state) {
    return state.inventoryList;
  },
  getPartCodeName(state) {
    return state.partCodeList;
  },
  getOldInventoryYear(state) {
    return state.oldInventoryYearList;
  },
  getOldInventoryModel(state) {
    return state.oldInventoryModelList;
  },
  getOldInventoryList(state) {
    return state.oldInventoryList;
  },
  getInventoryMetaInfo(state) {
    return state.inventoryMeta;
  },
  getTrim(state) {
    return state.trim;
  },
};

export const searchInventory = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
