<template>
  <v-app>
    <Loading :active="loader" :is-full-page="true">
      <template>
        <img class="rd-main-loader" src="./assets/img/loader.gif" alt="Loading" />
      </template>
    </Loading>
    <span class="vld-overlay is-active is-full-page" :active="loader"
      :style="'top: 57px;color: #2a9131;font-size: 25px;display:' + (loader ? 'flex' : 'none')"> {{ message }}</span>
    <div class="new-overlay" @click="toggleBodyClass"></div>
    <button class="btn toggle-btn" @click="toggleBodyClass">
      <img src="./assets//img/icon-close.svg" alt="">
    </button>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Loading: Loading,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      loader: "getLoaderStatus",
      message: "getLoaderMessage",
    }),
  },
  mounted() {
    if (
      window.IsDuplicate() &&
      !window.location.href.includes("duplicate-tab")
    ) {
      window.location.href = "/duplicate-tab";
    }
  },
  methods: {
    toggleBodyClass() {
      document.body.classList.toggle('sidebar-active');
    }
  },
};
</script>
