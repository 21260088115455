import interchangeService from "@/services/interchange.service";

const state = {
  manufacturer: [],
  modelYears: [],
  carline: [],
  pType: [],
  indexList: [],
  indexListApp: [],
  applications: [],
};

const actions = {
  getManufacturer({ commit }, query) {
    return interchangeService.getManufacturer(commit, query);
  },
  getCarline({ commit }, payload) {
    return interchangeService.getCarline(commit, payload);
  },
  getModelYears({ commit }) {
    return interchangeService.getModelYears(commit);
  },
  getPType({ commit }) {
    return interchangeService.getPType(commit);
  },
  getIndexList({ commit }, payload) {
    return interchangeService.getIndexList(commit, payload);
  },
  getIndexListApp({ commit }, payload) {
    return interchangeService.getIndexListApp(commit, payload);
  },
  getApplications({ commit }, payload) {
    return interchangeService.getApplications(commit, payload);
  },
};

const mutations = {
  setManufacturer(state, response) {
    state.manufacturer = response.data;
  },
  setCarline(state, response) {
    state.carline = response.data;
  },
  setModelYears(state, response) {
    state.modelYears = response.data;
  },
  setPType(state, response) {
    state.pType = response.data;
  },
  setIndexList(state, response) {
    state.indexList = response.data;
  },
  setIndexListApp(state, response) {
    state.indexListApp = response.data;
  },
  setApplications(state, response) {
    state.applications = response.data;
  },
};

const getters = {
  getManufacturer(state) {
    return state.manufacturer;
  },
  getCarline(state) {
    return state.carline;
  },
  getModelYears(state) {
    return state.modelYears;
  },
  getPType(state) {
    return state.pType;
  },
  getIndexList(state) {
    return state.indexList;
  },
  getIndexListApp(state) {
    return state.indexListApp;
  },
  getApplications(state) {
    return state.applications;
  },
};

export const interchange = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
