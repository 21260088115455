import http from "@/services/http.service";
import {
  GET_MARKETING_TAGS,
  SAVE_MARKETING_CONTACTS,
  UPDATE_MARKETING_CONTACTS,
  GET_MARKETING_CONTACTS,
  DELETE_MARKETING_CONTACTS,
  GET_MARKETING_COMPAIGN,
  SAVE_MARKETING_COMPAIGN,
} from "../constants/apiUrls";
import { ITEMS_PER_PAGE } from "@/constants/common";

const saveMarketingContacts = async (commit, data) => {
  await http.post(SAVE_MARKETING_CONTACTS, data);
  await getMarketingContacts(commit, {
    search: null,
    items_per_page: ITEMS_PER_PAGE,
    page: 1,
    type: data.type,
  });
};

const updateMarketingContacts = async (commit, data) => {
  await http.put(
    UPDATE_MARKETING_CONTACTS.replace("{contact_id}", data.id),
    data
  );
  await getMarketingContacts(commit, {
    search: null,
    items_per_page: ITEMS_PER_PAGE,
    page: 1,
    type: data.type,
  });
};

const getTags = async (commit, data) => {
  const response = await http.post(GET_MARKETING_TAGS, data);
  commit("setTagsSearch", response.data);
};

const getMarketingContacts = async (commit, payload) => {
  const response = await http.get(GET_MARKETING_CONTACTS, { params: payload });
  commit("setMarketingContactsSearch", response);
};

const deleteMarketingContacts = async (commit, data) => {
  await http.delete(DELETE_MARKETING_CONTACTS.replace("{contact_id}", data.id));
  await getMarketingContacts(commit, {
    search: null,
    items_per_page: ITEMS_PER_PAGE,
    page: 1,
    type: data.type,
  });
};

const getMarketingCampaign = async (commit, payload) => {
  const response = await http.get(GET_MARKETING_COMPAIGN, { params: payload });
  commit("setMarketingCampaignSearch", response);
};

const saveMarketingCompaign = async (commit, data) => {
  await http.post(SAVE_MARKETING_COMPAIGN, data);
};

export default {
  getMarketingContacts,
  saveMarketingContacts,
  updateMarketingContacts,
  deleteMarketingContacts,
  getMarketingCampaign,
  saveMarketingCompaign,
  getTags,
};
