import http from "@/services/http.service";
import { CAR_HISTORY, PREVIOUS_CAR_HISTORY } from "@/constants/apiUrls";

const getCarHistoryReport = async (commit, payload) => {
  const response = await http.post(CAR_HISTORY, payload);
  commit("setCarHistoryReport", response.data);
};

const getPreviousCarHistoryReport = async (commit, payload) => {
  const response = await http.get(PREVIOUS_CAR_HISTORY, { params: payload });
  commit("setPreviousCarHistoryReport", response);
};

export default {
  getCarHistoryReport,
  getPreviousCarHistoryReport,
};
