import http from "@/services/http.service";
import {
  GET_CARLINE,
  GET_INDEX_LIST,
  GET_INDEX_LIST_APP,
  GET_MANUFACTURER,
  GET_YEAR,
  GET_P_TYPE,
  GET_APPLICATIONS,
} from "@/constants/apiUrls";

const getManufacturer = async (commit, query) => {
  const response = await http.get(GET_MANUFACTURER, { params: query });
  commit("setManufacturer", response.data);
};

const getCarline = async (commit, payload) => {
  const response = await http.post(GET_CARLINE, payload);
  commit("setCarline", response.data);
  commit("setPType", []);
};

const getModelYears = async (commit) => {
  const response = await http.get(GET_YEAR);
  commit("setModelYears", response.data);
  commit("setPType", []);
};

const getPType = async (commit) => {
  const response = await http.get(`${GET_P_TYPE}`);
  commit("setPType", response.data);
};

const getIndexList = async (commit, payload) => {
  const response = await http.post(GET_INDEX_LIST, payload);
  commit("setIndexList", response.data);
};

const getIndexListApp = async (commit, payload) => {
  const response = await http.post(GET_INDEX_LIST_APP, payload);
  commit("setIndexListApp", response.data);
};

const getApplications = async (commit, payload) => {
  const response = await http.post(GET_APPLICATIONS, payload);
  commit("setApplications", response.data);
};

export default {
  getManufacturer,
  getCarline,
  getModelYears,
  getPType,
  getIndexList,
  getIndexListApp,
  getApplications,
};
