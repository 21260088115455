import dmvProcessing from "@/services/dmvProcessing.service";

const state = {
  dmv: [],
  carProfiles: [],
  carProfileMeta: {},
  dmvProcessing: [],
  dmvProcessingMeta: {},
  dmvDetail: {},
};

const actions = {
  getCarProfiles({ commit }, payload) {
    return dmvProcessing.getCarProfiles(commit, payload);
  },
  getDmvProcessing({ commit }, payload) {
    return dmvProcessing.getDmvProcessing(commit, payload);
  },
  saveDmv({ commit }, query) {
    return dmvProcessing.saveDmv(commit, query);
  },
  updateDmv({ commit }, query) {
    return dmvProcessing.updateDmv(commit, query);
  },
};

const mutations = {
  setCarProfiles(state, response) {
    state.carProfileMeta = response.meta;
    state.carProfiles = response.data;
  },
  setDmvProcessing(state, response) {
    state.dmvProcessingMeta = response.meta;
    state.dmvProcessing = response.data;
  },
};

const getters = {
  getCarProfiles(state) {
    return state.carProfiles;
  },
  getDmvProcessing(state) {
    return state.dmvProcessing;
  },
  getCarProfileMetaInfo(state) {
    return state.carProfileMeta;
  },
  getDmvProcessingMetaInfo(state) {
    return state.dmvProcessingMeta;
  },
};

export const dmv = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
